import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from 'primereact/button'
import { Divider } from 'primereact/divider'

function SidebarSection(props) {
  const history = useHistory()

  function onMenuClick(navTo) {
    console.log(navTo)
    history.push(navTo)
  }

  return (
    <div className="p-field layout-sidebar p-col-fixed ">
      <Button label="Valheim Servers" className="p-button-text p-d-flex" onClick={() => onMenuClick('/servers')} />
      <Button label="Worlds" className="p-button-text p-d-flex" onClick={() => onMenuClick('/worlds')} />
      <Divider align="center" />
      <Button label="New Server" className="p-button-text p-d-flex" onClick={() => onMenuClick('/create_server')} />
      <Button label="My Servers" className="p-button-text p-d-flex" onClick={() => onMenuClick('/my_servers')} />
    </div>
  )
}

export default SidebarSection