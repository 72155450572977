import React from 'react'
import { useHistory } from 'react-router-dom'
import { UserInfoWidget } from '../UserInfoWidget'
import { Button } from 'primereact/button'

//import logo from '../images/squidman.jpg'
import logo from '../images/stickman.jpg'
//import logo from '../images/hammerman.jpg'

import './HeaderSection.css'

function HeaderSection(props) {
  const history = useHistory()

  function onMenuClick(navTo) {
    console.log(navTo)
    history.push(navTo)
  }

  return (
    <div className="odin-header p-card p-d-flex p-p-3 card p-ai-end">
      <img src={logo} className="logo p-mr-2 p-mb-2" alt="logo" />
      <div className="title">Odin's World</div>
      <div className="p-ml-auto"><UserInfoWidget /></div>
    </div>
  )
}

export default HeaderSection

