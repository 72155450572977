import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

class ServerList extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.state.isLoaded = false
    this.state.servers = { count: 0, records: [] }
    // this.state.isLoaded = true
    // this.state.worlds = { "count": 8, "records": [{ "name": "TempX", "id": "d3226ce17b73e95c89e8dd40ec5db343", "status": "stopped", "password": "aaaaaaaa", "statusUpdatedTime": "-", "instanceId": "", "serverIp": "" }, { "name": "Server 85", "id": "f48acda2feb65e4c6e7368c2eb977942", "status": "stopped", "password": "abcd1234", "statusUpdatedTime": "-", "instanceId": "", "serverIp": "" }, { "name": "LoTan's World", "id": "1c69892a3fc4f699a41f813f0b53e98b", "status": "stopped", "password": "8Sf4Nkz&g", "statusUpdatedTime": "-", "instanceId": "", "serverIp": "" }, { "name": "Another world2", "id": "75dacce1ac36660847119052cc4050c1", "status": "stopped", "password": "kWRjhYVmU", "statusUpdatedTime": "-", "instanceId": "", "serverIp": "" }, { "name": "TheDen", "id": "a2948bf90f84bf73c925c91745494d51", "status": "stopped", "password": "12345678", "statusUpdatedTime": "-", "instanceId": "", "serverIp": "" }, { "name": "Timmeh", "id": "3225b118b9676fb069a25522b906fe02", "status": "stopped", "password": "12345678", "statusUpdatedTime": "-", "instanceId": "", "serverIp": "" }, { "name": "AsdfTuna", "id": "fae56f96b00e4497537677874e3a233f", "status": "stopped", "password": "12345678", "statusUpdatedTime": "-", "instanceId": "", "serverIp": "" }, { "name": "Final Countdown", "id": "4fbcf5025dc8af200fff7e88847728a5", "status": "stopped", "password": "12345678", "statusUpdatedTime": "-", "instanceId": "", "serverIp": "" }] }
    this.handleClick = this.handleClick.bind(this)
    this.onRowClicked = this.onRowClicked.bind(this)
  }

  handleClick(serverId) {
    const { history } = this.props
    history.push(`/server/${serverId}`)
  }

  onRowClicked(row) {
    const { history } = this.props
    history.push(`/server/${row.data.id}`)
  }

  /**
   * Component Did Mount
   */
  async componentDidMount() {
    fetch('/api/servers', {
      headers: { 'Cache-Control': 'no-cache' }
    })
      .then(res => res.json())
      .then(servers => {
        this.setState({
          isLoaded: true,
          servers
        })
      }).catch(err => {
        this.setState({
          isLoaded: true,
          err
        })
      })
  }

  /**
   * Render
   */
  render() {
    const { error, isLoaded, servers } = this.state
    if (error) {
      return <div>Error loading worlds</div>
    } else if (!isLoaded) {
      return <div>Loading world list...</div>
    } else {
      return (
        <div>
          <div className="card">
            <DataTable value={servers.records} className="p-datatable-striped" rowHover={true} onRowClick={this.onRowClicked}>
              <Column className="o-table-row" field="name" header="Server Name"></Column>
              <Column className="o-table-row" field="serverIp" header="IP"></Column>
              <Column className="o-table-row" field="status" header="Status"></Column>
              <Column className="o-table-row" field="password" header="Password"></Column>
              <Column className="o-table-row" field="worldName" header="World"></Column>
            </DataTable>
          </div>
        </div>
      )
    }
  }
}

export default withRouter(ServerList)