import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { ServerStatusPanel } from './ServerWidgets'
import { InputText } from 'primereact/inputtext'
import { ToggleButton } from 'primereact/togglebutton'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'

function ServerControlPanel(props) {
  let { id } = useParams()
  let toast = useRef(null)
  let [isLoaded, setIsLoaded] = useState(false)
  let [error, setError] = useState(false)
  let [serverData, setServerData] = useState({})
  let [serverName, setServerName] = useState('')
  let [serverPassword, setServerPassword] = useState('')
  let [serverPublic, setServerPublic] = useState(false)
  const searchParams = useLocation().search
  const serverCreated = new URLSearchParams(searchParams).get('success')

  const openToaster = useCallback(toaster => {
    if (serverCreated && toaster && toaster.show) {
      toaster.show({severity: 'success', summary: 'Your new server has been created!'})
    }
  }, [id, serverCreated])

  function handleSubmit() {
    if (!serverName) {
      toast.current.show({severity: 'error', summary: 'Please enter a valid server name'})
      return false
    }

    if (!serverPassword) {
      toast.current.show({severity: 'error', summary: 'Please enter a server password'})
      return false
    } else if (serverPassword.length < 5 || serverPassword.length.length > 20) {
      toast.current.show({severity: 'error', summary: 'Server password must be between 5 and 20 characters'})
      return false
    }

    const body = { name: serverName, password: serverPassword, publicAccess: serverPublic }
    console.log(`Updating server ${id} body: ${JSON.stringify(body)}`)
    fetch(`/api/servers/${id}`, {
      method: 'put',
      body: JSON.stringify(body),
      headers: { 'Content-Type': 'application/json' }
    })
      .then(res => res.json())
      .then(text => {
        toast.current.show({severity: 'success', summary: 'Server settings updated'})
        console.log(text)
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error updating server settings'})
        console.log(error)
      })
  }

  useEffect(() => {
    if (id) {
      fetch(`/api/servers/${id}`, {
        headers: { 'Cache-Control': 'no-cache' }
      })
        .then(res => {
          return Promise.all([res, res.json()])
        })
        .then((result) => {
          if (result[0].ok) {
            const serverDetail = result[1]
            setServerName(serverDetail.name)
            setServerPassword(serverDetail.password)
            setServerData(serverDetail)
            setServerPublic(false)
            setIsLoaded(true)
          } else {
            throw new Error(result[1].message)
          }
        }).catch(err => {
          setError(err.message)
          setIsLoaded(true)
        })
    }
  }, [ id, toast, serverCreated ])

  /**
   * Render
   */
  if (error) {
    return <div className="p-p-4">{error}</div>
  } else if (!isLoaded) {
    return <div className="p-p-4">Loading server data...</div>
  } else {
    return (
      <div className="p-p-4">
        <ServerStatusPanel {...serverData} />
        <form onSubmit={e => e.preventDefault()}>
          <Toast ref={toast} position="bottom-center" />
          <Toast ref={openToaster} position="bottom-center" />
          <div className="p-grid">
            <div className="p-col">
              <div className="p-fluid">
                <div className="p-field p-text-left">
                  <label htmlFor="name">Server Name</label>
                  <InputText autoFocus id="name" type="text" value={serverName} className="p-inputtext-sm" onChange={(e) => setServerName(e.target.value)} />
                </div>
                <div className="p-field p-text-left">
                  <label htmlFor="password">Password</label>
                  <InputText id="password" type="text" value={serverPassword} className="p-inputtext-sm" onChange={(e) => setServerPassword(e.target.value)} />
                </div>
              </div>
              <div className="p-d-flex p-flex-column p-ai-start p-mb-3">
                <div className="p-mb-2">
                  <label htmlFor="publicPassword">Public Password</label>
                </div>
                <div>
                  <ToggleButton id="publicPassword" onIcon="pi pi-check" offIcon="pi pi-times"
                    checked={serverPublic} onLabel="Yes" offLabel="No"
                    onChange={(e) => setServerPublic(e.value)} />
                </div>
                <div>
                  {!serverPublic && <small>Allow approved users to view server password</small>}
                  {serverPublic && <small>Allow anyone to view server password</small>}
                </div>
                <div className="p-field">
                  <Button className="p-button-sm p-button-primary p-text-center p-pl-3 p-pr-3 p-mt-4" onClick={handleSubmit}>Save Changes</Button>
                </div>
              </div>
            </div>
            <div className="p-col">
              &nbsp;
            </div>
          </div>
        </form>
        <div><a href={"/api/servers/" + id + "/downloadWorld"}>Download World</a></div>
      </div>
    )
  }
}

{/* <form noValidate autoComplete="off">
          <FormControl fullWidth variant="outlined">
            <TextField
              autoFocus
              id="name"
              label="Server Name"
              color="secondary"
              onChange={e => this.setState({ name: e.target.value })}
              value={this.state.name}
            />
          </FormControl>
          <FormControl fullWidth variant="outlined">
            <TextField
              id="password"
              label="Server Password"
              color="secondary"
              onChange={e => this.setState({ password: e.target.value })}
              value={this.state.password}
            />
          </FormControl>
          <Button size="medium" color="primary" variant="contained" onClick={handleSubmit}>Save Changes</Button>
        </form> */}

export default ServerControlPanel