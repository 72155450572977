import React, { Component, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { ToggleButton } from 'primereact/togglebutton'
import { Toast } from 'primereact/toast'

class CreateServer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mode: 'add',
      insufficientCredits: true,
      isLoaded: false,
      publicPassword: false,
      name: '',
      password: ''
    }
    this.history = this.props.history
    this.toast = React.createRef(null)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit() {
    if (!this.state.password || this.state.password.length < 5) {
      this.toast.current.show({severity: 'warn', summary: 'Password must be between 5-20 characters, and be alphanumeric'})
      return
    }

    if (this.state.name) {
      console.log('Submitting new world to backend. State:')
      console.log(this.state)
      const body = {
        name: this.state.name,
        password: this.state.password
      }
      fetch('/api/servers', {
        method: 'post',
        body: JSON.stringify(body),
        headers: { 'Content-Type': 'application/json' }
      })
        .then(res => res.json())
        .then(result => {
          console.log(JSON.stringify(result))
          this.history.push(`/server/${result.serverId}?success=true`)
        })
        .catch(error => {
          this.toast.current.show({severity: 'error', summary: 'Failed to create server'})
          console.log(error)
        })
    }
  }

  /**
   * Component Did Mount
   */
  async componentDidMount() {
    // TODO: Pull credits and disable submit button until we know how many they have
    // If input is disabled
  }

  /**
   * Render
   */
  render() {
    return (
      <form onSubmit={e => e.preventDefault()}>
        <Toast ref={this.toast} position="bottom-center" />
        <div className="p-grid p-p-4">
          <div className="p-col">
            <div className="p-fluid">
              <div className="p-field p-text-left">
                <label htmlFor="name">Game</label>
                <InputText disabled autoFocus id="name" type="text" className="p-inputtext-sm" value="Valheim" />
                <small>All servers currently run Valheim only</small>
              </div>
              <div className="p-field p-text-left">
                <label htmlFor="name">Server Name</label>
                <InputText autoFocus id="name" type="text" value={this.state.name} className="p-inputtext-sm" onChange={(e) => this.setState({ name: e.target.value })} />
              </div>
              <div className="p-field p-text-left">
                <label htmlFor="password">Password</label>
                <InputText id="password" type="text" value={this.state.password} className="p-inputtext-sm" onChange={(e) => this.setState({ password: e.target.value })} />
              </div>
            </div>
            <div className="p-d-flex p-flex-column p-ai-start p-mb-3">
              <div className="p-mb-2">
                <label htmlFor="publicPassword">Public Password</label>
              </div>
              <div>
                <ToggleButton id="publicPassword" onIcon="pi pi-check" offIcon="pi pi-times"
                  checked={this.state.publicPassword} onLabel="Yes" offLabel="No"
                  onChange={(e) => this.setState({ publicPassword: e.value })} />
              </div>
              <div>
                {!this.state.publicPassword && <small>Allow approved users to view server password</small>}
                {this.state.publicPassword && <small>Allow anyone to view server password</small>}
              </div>
              <div className="p-field">
                <Button className="p-button-sm p-button-primary p-text-center p-pl-3 p-pr-3 p-mt-4" onClick={this.handleSubmit}>Create Server</Button>
              </div>
            </div>
          </div>
          <div className="p-col">
            &nbsp;
          </div>
        </div>
      </form>
    )
  }
}

export default withRouter(CreateServer)