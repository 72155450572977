import React, { useState, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { Avatar } from 'primereact/avatar'

import './UserInfoWidget.css'

function UserInfoWidget(props) {
  const [hi, setHi] = useState(0)
  const [isLoggingIn, setisLoggingIn] = useState(false)
  const [cookies, setCookie, removeCookie] = useCookies(['email'])

  const loginUrl = `https://${process.env.REACT_APP_AUTH_DOMAIN}/login?response_type=code&client_id=${process.env.REACT_APP_AUTH_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_AUTH_REDIRECT_URL}`
  const logoutUrl = `/auth/logout`

  function logout(e) {
    e.preventDefault()

    fetch(logoutUrl).then(() => {
      cookies.email = undefined
      removeCookie('email', { path: '/', domain: process.env.COOKIE_DOMAIN, secure: false })
      console.log('Logout successful')
      setHi((c) => c + 1) // force render
    })
  }

  if (isLoggingIn) {
    return <div>Signing in</div>
  } else if (cookies.email) {
    return (
      <div className="user-info-widget">
        {/* <Avatar image={cookies.profile} shape="circle" className="avatar"></Avatar> */}
        {cookies.email} (<a href="#" onClick={logout}>Logout</a>)
      </div>
    )
  } else {
    return <div><a href={loginUrl}>Login</a></div>
  }
}

export { UserInfoWidget }