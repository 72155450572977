import React, { useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import useInterval from '@use-it/interval'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import Button from '@material-ui/core/Button'
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite'
import StopIcon from '@material-ui/icons/Stop'
import BackupIcon from '@material-ui/icons/Backup'
import RefreshIcon from '@material-ui/icons/Refresh'
import { Toast } from 'primereact/toast'

dayjs.extend(utc)

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(0.5),
  },
}));

const transitionStatuses = ['pending', 'stopping', 'shutting-down']
const stoppedStatuses = ['stopped', 'terminated']

function isServerTransitioning(status, updatedAt) {
  return transitionStatuses.includes(status) ||
    (status === 'running' && dayjs.utc().diff(updatedAt, 'm') < 3)
}

function getRenderStatus(status, isTransitioning) {
  if (status === 'running' && isTransitioning) {
    return 'starting'
  } else {
    return status
  }
}

function ServerStatusPanel(props) {
  const serverId = props.id
  const classes = useStyles();
  const toast = useRef(null)
  const isMoving = isServerTransitioning(props.status, props.statusUpdatedTime)
  const [status, setStatus] = useState(getRenderStatus(props.status, isMoving))
  const [isTransitioning, setIsTransitioning] = useState(isMoving)
  const [ipAddress, setIpAddress] = useState(props.serverIp)

  function onStatusReceived(status, updatedAt) {
    if (dayjs.utc(updatedAt).isValid()) {
      const isMoving = isServerTransitioning(status, updatedAt)
      const renderStatus = getRenderStatus(status, isMoving)
      setIsTransitioning(isMoving)
      setStatus(renderStatus)
      //console.log(`${status} was updated at ${updatedAt}, ${lastStatusChangeMinutesOld} minute(s) ago. isTransitioning = ${isMoving}`)
    } else {
      console.log(`Updated at (${updatedAt}) is invalid and date comparisons cannot be made`)
      setIsTransitioning(false)
    }
  }

  function executeServerAction(action, isTransitional, status) {
    fetch(`/api/servers/${serverId}/${action}`)
      .then((response) => {
        if (!response.ok) {
          if (action === 'backup') {
            throw new Error('Failed to backup server world files')
          } else {
            throw new Error(`Failed to ${action} the server`)
          }
        }
        return response.json()
      })
      .then((result) => {
        if (status) {
          setStatus(status)
        }
        setIsTransitioning(isTransitioning)
        toast.current.show({severity: 'success', summary: result.message})
      })
      .catch((error) => {
        toast.current.show({severity: 'error', summary: error.message})
      })
  }

  function isStopped() {
    return isTransitioning || stoppedStatuses.includes(status)
  }

  function startServer() {
    console.log('Starting the server!')
    executeServerAction('start', true, 'pending')
  }

  function stopServer() {
    console.log('Stopping the server!')
    executeServerAction('stop', true, 'stopping')
  }

  function restartServer() {
    console.log('Restarting the server instance')
    executeServerAction('restart', false)
  }

  function backupWorld() {
    console.log('Backing up the world files to S3')
    executeServerAction('backup', false)
  }

  function getStatus() {
    console.log('Refreshing server status')
    fetch(`/api/servers/${serverId}`, {
      headers: { 'Cache-Control': 'no-cache' }
    }).then(res => res.json())
      .then(serverDetail => {
        onStatusReceived(serverDetail.status, serverDetail.statusUpdatedTime)
        setIpAddress(serverDetail.serverIp)
      }).catch(err => {
        console.log(err)
        
      })
  }

  useInterval(() => {
    getStatus()
  }, 5000)

  return (
    <div>
      <Toast ref={toast} position="bottom-center" />
      <Button
          variant="contained"
          color="primary"
          disabled={isTransitioning || status==='running'}
          aria-label="Start game server"
          className={classes.button}
          startIcon={<PlayCircleFilledWhiteIcon/>}
          onClick={() => startServer()}>
        Start
      </Button>
      <Button
          variant="contained"
          color="secondary"
          disabled={ isStopped() }
          aria-label="Stop game server"
          className={classes.button}
          startIcon={<StopIcon />}
          onClick={() => stopServer()}>
        Stop
      </Button>
      <Button
          variant="contained"
          disabled={ isStopped() }
          aria-label="Back-up world files"
          className={classes.button}
          startIcon={<BackupIcon />}
          onClick={() => backupWorld()}>
        Back Up
      </Button>
      <Button
          variant="contained"
          disabled={ isStopped() }
          aria-label="Restart"
          className={classes.button}
          startIcon={<RefreshIcon />}
          onClick={() => restartServer()}>
        Restart
      </Button>
      <h1>Server status: {status}  {status === 'running' ? (ipAddress + ':2456') : ''}</h1>
    </div>
  )
}

export { ServerStatusPanel }