import React, { Component } from 'react';
import {
  Link
} from "react-router-dom";
import imageHero from './images/valheim_logo_trans.png';
import { UserInfoWidget } from './UserInfoWidget'

export default class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.state.loginUrl = `https://${process.env.REACT_APP_AUTH_DOMAIN}/login?response_type=code&client_id=${process.env.REACT_APP_AUTH_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_AUTH_REDIRECT_URL}`
  }

  /**
   * Component Did Mount
   */
  async componentDidMount() { }

  /**
   * Render
   */
  render() {
    return (
      <div className="container">
        <nav>
          <ul>
            <li>
              <UserInfoWidget />
            </li>
            <li>
              <Link to="/servers">Servers</Link>
            </li>
            <li>
              <Link to="/create_server">Create Server</Link>
            </li>
          </ul>
        </nav>
      </div>
    )
  }
}
