import React, { useState, useRef } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom"
import Navigation from './Navigation'
import ServerList from './ServerList'
import CreateServer from './CreateServer'
import ServerControlPanel from './ServerControlPanel'
import { UserLogin } from './UserLogin'
import { CookiesProvider } from 'react-cookie'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import SidebarSection from './section/SidebarSection'
import HeaderSection from './section/HeaderSection'
import { Panel } from 'primereact/panel'
import { Sidebar } from 'primereact/sidebar'
import PrimeReact from 'primereact/api'
import { Ripple } from 'primereact/ripple'

import 'primereact/resources/themes/saga-blue/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'

import './App.css'

function App() {
  const [text, setText] = useState('')
  const toastRef = useRef()

  PrimeReact.ripple = true

  const onFormSubmit = (e) => {
    if (text) {
      toastRef.current.show({ severity: 'info', summary: text, life: 3000 });
    }

    // clear
    setText('');

    e.preventDefault();
  }

  return (
    <div className="App">
      <CookiesProvider>
        <Toast ref={toastRef} />
        <Router>
          <HeaderSection />
          <div className="p-grid">
            <SidebarSection />
            <div className="content-section p-col">
              <div>
                {/* <Navigation /> */}
                <Switch>
                  <Route path="/user/login">
                    <UserLogin />
                  </Route>
                  <Route path="/server/:id">
                    <ServerControlPanel />
                  </Route>
                  <Route path="/servers">
                    <ServerList />
                  </Route>
                  <Route path="/create_server">
                    <CreateServer />
                  </Route>
                  <Route path="/">
                    <ServerList />
                  </Route>
                </Switch>
              </div>
            </div>
          </div>
        </Router>
      </CookiesProvider>

      {/* <div className="box flexbox-demo p-d-flex p-mb-3">Flex Container</div> */}

      {/* <div className="p-d-flex">
        <div className="flexbox-demo p-mr-2">Item 1</div>
        <div className="flexbox-demo p-mr-2">Item 2</div>
        <div className="flexbox-demo">Item 3</div>
      </div> */}

      {/* <form className="">
        <InputText value={text} onChange={(e) => setText(e.target.value)} className="p-inputtext-sm" />
        <Button type="submit" label="Submit" icon="pi pi-check" className="p-ml-2 p-button-sm" />
      </form>

      <div className="p-input-filled">
        <form className="p-d-flex p-jc-center p-mt-6" onSubmit={onFormSubmit}>
          <InputText value={text} onChange={(e) => setText(e.target.value)} className="p-inputtext-sm" />
          <Button type="submit" label="Submit" icon="pi pi-check" className="p-ml-2 p-button-sm" />
        </form>
      </div> */}
    </div>
  )
}

export default App