import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useCookies } from 'react-cookie'

function UserLogin(props) {
  const [cookies, setCookie] = useCookies(['bleh'])
  const history = useHistory()
  const searchParams = useLocation().search
  const code = new URLSearchParams(searchParams).get('code')

  useEffect(() => {
    async function fetchAuth() {
      console.log('fetching user auth tokens via auth code ' + code)

      var myHeaders = new Headers();
  
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
        credentials: 'same-origin'
      };

      const resp = await fetch(`/auth/exchange?code=${code}`, requestOptions)
      const json = await resp.json()

      console.log(JSON.stringify(json))

      console.log(`Setting email cookie to ${json.userData.email} for domain ${process.env.COOKIE_DOMAIN}`)

      const cookieSettings = { path: '/', domain: process.env.COOKIE_DOMAIN, secure: false }
      setCookie('email', json.userData.email, cookieSettings)
      setCookie('profile', json.userData.profile, cookieSettings)
      history.push("/servers")
    }

    fetchAuth().catch(err => console.log(err))
  })

  return ( <div /> )
}

export { UserLogin }
